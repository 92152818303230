import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ArticleGrid from "../components/ArticleGrid";
import FilterCategory from "../components/FilterCategory";
import EmailUsBlock from "../components/EmailUsBlock";
import Seo from "../components/seo.jsx";
import ogIMage from "../images/og.png";
const BlogCategory = ({ data, location }) => {
    return (
        <Layout>
            <Seo
                title={data.pageData.metaTitle}
                description={data.pageData.metaDescription}
                image={`https://sedintechnologies.com${ogIMage}`}
                url={location.href}
            />
            <section className="blog-category">
                <div className="container container--first-v3">
                    <div className="blog-category__text">
                        <h1 className="text-h1 text-clr-primary text-fw-medium">
                            {data.pageData.categoryType}
                        </h1>
                    </div>
                    <FilterCategory uniqueCategory={data.allContentfulBlogCategory.nodes} icon />
                    <ArticleGrid featuredArticles={data.pageData.blogs} />
                </div>
            </section>
            <section className="email">
                <div className="container container--last-v2">
                    <EmailUsBlock />
                </div>
            </section>
        </Layout>
    );
};

export default BlogCategory;
export const query = graphql`
    query blogPageQuery($id: String) {
        pageData: contentfulBlogCategory(id: { eq: $id }) {
            id
            categoryType
            metaTitle
            metaDescription
            description {
                description
            }

            blogs {
                title
                readTime
                author
                slug
                blogImage {
                    gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                }
                categories {
                    categoryType
                    slug
                }
            }
        }
        allContentfulBlogCategory {
            nodes {
                categoryType
                slug
            }
        }
    }
`;
